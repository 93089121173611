<template>
  <div class="container-base section-container-padding no-padding-left bg-white row ml-0 pl-0">
    <settings-tabs tab-name="my-instances" class="col-2 pl-0 pr-0"/>
    <div class="myproj-container settings-my-instances-tabs col-10">
        <b-row>
            <b-tabs class="col-12" content-class="mt-3">
                <b-tab>
                    <template v-slot:title>
                        <img :src="'/assets/images/icon_instances.svg'"/><span>
                          {{ $t('settings.menu.tab.myInstances.title') }}
                        </span>
                    </template>
                    <div class="col-12 margin-cards">
                        <b-card v-for="instance in instances" :key="instance.bot_name" 
                        :class="[cardDisplay == 'simplified' ? 'simplified-card' : '',instance.bot_name == currentInstance ? 'active-instance' : '']"
                        class="percent-card" 
                        header-text-variant="white" 
                        header-tag="header">
                            <b-row>
                                <b-col lg="12" class="text-align-left">
                                <b-card-title v-if="cardDisplay == 'detailed'" class="utility-card-title openSans-400 fz-17"><span>{{instance.bot_name}}</span></b-card-title>
                                <b-row>
                                    <b-col v-if="cardDisplay == 'simplified'" lg="8" class="m-auto">
                                        <span class="utility-card-title openSans-400 fz-17 " style="text-transform: capitalize">{{instance.bot_name}}</span>
                                    </b-col>
                                    <b-col v-if="cardDisplay == 'detailed'" lg="1" >
                                        <div class="utility-card-icon-border-box">      
                                            <img class="instance-icon" :src="`/assets/images/icon_utility_insights.svg`">
                                        </div>
                                    </b-col>
                                    <b-col class="m-auto" v-if="cardDisplay == 'detailed'" lg="7">
                                        <b-card-text class="utility-card-text openSans-100 fz-14">
                                          {{instance.display_name}}
                                        </b-card-text>
                                    </b-col>
                                    <b-col lg="4" class="ta-left m-auto">
                                        <b-button @click="selectInstance(instance)" class="openSans-600 fz-12 ta-left button-secondary m-auto" size="sm">
                                            <b-icon-box></b-icon-box><span class="mx-1">
                                              {{ $t('settings.menu.tab.myinstances.btnLaunchInstance') }}
                                            </span>
                                        </b-button>
                                    </b-col>
                                </b-row>
                                </b-col>
                            </b-row>
                        </b-card>
                    </div>
                </b-tab>
                <b-tab v-if="canCreateBot()">
                    <template v-slot:title>
                        <img src="/assets/images/icon_add.svg"/>
                        <span>Add New Instance</span>
                    </template>
                    <add-new-instance :callback="getUserInstances"/>
                </b-tab>

                <template #tabs-end>
                    <li class="margin-label-view tabs-display-margin-project openSans-400 fz-13 utility-card-title">
                        {{ $t('settings.menu.tab.myInstances.view') }}
                    </li>
                    <b-nav-item href="#" role="presentation" class="display-change-icons" @click="changeDisplay('detailed')">
                        <img :class="cardDisplay == 'detailed' ? 'active-display' : ''" :src="'/assets/images/icon_detailed.svg'"/>
                    </b-nav-item>
                    <b-nav-item href="#" role="presentation" class="display-change-icons margin-simplified" @click="changeDisplay('simplified')">
                        <img :class="cardDisplay == 'simplified' ? 'active-display' : ''" :src="'/assets/images/icon_di_documentview.svg'"/>
                    </b-nav-item>
                </template>
            </b-tabs>
        </b-row>
    </div>
  </div>
</template>
<script>

import { PERM_MODIFY_SETTINGS } from '@/modules/security/services/SecurityService.js'
import {SET_CURRENT_INSTANCE} from '@/modules/security/store/actions/PermissionsActions'
import store from '@/store'
import AddNewInstance from "@/modules/settings/components/AddNewInstance";
import {GET_USER} from "@/modules/security/store/actions/UserActions";
import SettingsTabs from "@/modules/settings/components/SettingsTabs";

export default {
    name: 'TabMyInstances',
    components: { AddNewInstance,SettingsTabs},
    data() {
        return {
            bots: [],
            cardDisplay: 'simplified',
        }
    },
    methods: {
        create() {
            this.bots = store.getters.getCurrentUser.bots
        },
        selectInstance(instance) {
            store.dispatch(SET_CURRENT_INSTANCE, { instanceName: instance.bot_name })
            this.$router.push(`/${instance.bot_name}/settings/my-instances`)
        },
        changeDisplay: function(display) {
            display == 'detailed' ? this.cardDisplay = 'detailed' : this.cardDisplay = 'simplified'
        },
        getUserInstances() {
            store.dispatch(GET_USER)
        },
        canCreateBot() {
            return this.botPermissions.indexOf(PERM_MODIFY_SETTINGS) > -1
        }
    },
    computed: {
        botPermissions() {
            return store.getters.getInstancePermissions
        },
        instances() {
            return store.getters.getCurrentUser.bots
        },
        currentInstance() {
            return this.$router.currentRoute.params.bot ? this.$router.currentRoute.params.bot : store.getters.getInstance
        }

    }
}
</script>
<style scoped>

.fixed-buttons{
    float: right;
    position: absolute;
    z-index: 3;
    top: 0px;
    right: 70px;
}

.first{
    right:140px;
}

a.btn-secondary.btn-sm{
  display:flex;
  max-width:140px;
}

.button-secondary svg{
  margin-right: 0.25rem !important;
}

span.mx-1{
  margin: auto !important;
}

.percent-card {
  min-height: 120px;
  height:auto;
  margin-bottom:30px;
  width:96%;
}

.percent-card {
  min-height: 120px;
  height:auto;
  margin-bottom:30px;
  width:96%;
}

.simplified-card{
    min-height:80px;
}

.utility-card-icon-border-box{
  border:1px solid #ebebeb;
  width:40px;
  height:40px;
  display:table-cell;
  vertical-align:middle
}

div.utility-card-icon-border-box img{
  margin: 0 auto;
}

.margin-label-view {
  margin: auto 0px;
}
</style>