<template>
  <div class="container-base section-container-padding no-padding-left bg-white row ml-0 pl-0">
    <settings-tabs tab-name="search-settings" class="col-2 pl-0 pr-0"/>
    <div class="myproj-container settings-my-instances-tabs col-10 mt-3">

      <b-alert
        class="text-left"
        :show="dismissCountDown"
        dismissible
        variant="success"
        @dismissed="dismissCountDown=0"
        @dismiss-count-down="countDownChanged">
        {{ $t('settings.labels.successMessage') }}
      </b-alert>

      <b-row class="d-flex">
        <div class="li-utility-set-container">

          <br>
          <b-row>
            <b-col>
              <div style="text-align:left; padding-bottom:11px; border-bottom: 1px solid #d3d3d3; width: 100%;">
                <span class="ontology-sections-title">Search Settings</span>
              </div>
            </b-col>
          </b-row>
          <div class="text-left">
            <br>
            <span class="section-title">Ask Page</span>
            <br>
            <b-form-group>
              <template>
                <b-form-checkbox v-for="setting in searches"
                                 v-model="configSearches"
                                 :key="setting.key"
                                 :value="setting.key"
                                 stacked>
                  {{ setting.text}}
                </b-form-checkbox>
              </template>
            </b-form-group>
          </div>
          <b-row class="li-header-initial-row text-left">
            <b-col cols="12">
              <div class="d-flex">
                <b-button class="button-primary" @click="configureSearch">Save</b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import SettingsTabs from "@/modules/settings/components/SettingsTabs";
import SettingsService from "@/modules/settings/services/SettingsService";
import {mapState} from "vuex";
import store from "@/store";
export default {
name: "search-settings",
  components: {SettingsTabs},
  data() {
    return {
      configSearches: [],
      searches: [],
      dismissSecs: 7,
      dismissCountDown: 0
    }
  },
  methods: {
    getAsKConfig() {
      SettingsService.getAskConfig(this.currentBot).then(response => {
        response.data['search_settings'].forEach(setting => {
          this.searches.push({
            text: setting[Object.keys(setting)[1]],
            key: Object.keys(setting)[0]
          })
        })
        response.data['search_settings'].forEach(currentSetting => {
          if(currentSetting[Object.keys(currentSetting)[0]] === 'true') {
            this.configSearches.push(Object.keys(currentSetting)[0])
          }
        })
      })
    },
    configureSearch() {
      let botSettings = {}
      this.searches.forEach(setting => {
        botSettings[setting.key] = this.configSearches.includes(setting.key)? 'true' : 'false';
      })

      let botName = this.$router.currentRoute.params.bot
      SettingsService.configSearch(botSettings, botName).then(() => {
        this.showAlert()
        this.searches = []
        this.configSearches = []
        this.getAsKConfig()
      })
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    }
  },
  watch: {
    currentBot() {
      this.getAsKConfig()
    }
  },
  computed: mapState({
    currentBot: () => store.getters.getInstance
  }),
  created() {
    if (this.$store.getters.hasSelectedInstance) {
      this.getAsKConfig()
    }
  }
}
</script>

<style scoped>

</style>