<template>
  <div class="text-left">
    <b-form-select
      :id="role"
      v-model="selected"
      :options="options"
      class="mb-3 btn-secondary-myproj"
      value-field="item"
      text-field="name"
      disabled-field="notEnabled"
      @change="changeHomepage"></b-form-select>
  </div>
</template>

<script>
export default {

  name: 'HomePageSelect',

  data() {
    return {
      selected: {},
      options: [{
        item: {
          name: "Default",
          url: "",
          permission: ""
        },
        name: "Default"
      }, {
        item: {
          name: "Ask",
          url: "/ask/",
          permission: ""
        },
        name: "Ask"
      }, {
        item: {
          name: "Training",
          url: "/training/",
          permission: ""
        },
        name: "Training"
      }, {
        item: {
          name: "Issues",
          url: "/issues/",
          permission: "PERM_VIEW_ISSUES"
        },
        name: "Issues"
      }, {
        item: {
          name: "Q&A",
          url: "/questions/",
          permission: "PERM_VIEW_QA"
        },
        name: "Q&A"
      }, {
        item: {
          name: "Library",
          url: "/library/",
          permission: "PERM_VIEW_LIBRARY"
        },
        name: "Library"
      }, {
        item: {
          name: "Dashboard",
          url: "/dashboard/",
          permission: "PERM_VIEW_DASHBOARD"
        },
        name: "Dashboard"
      }, {
        item: {
          name: "Settings",
          url: "/settings/",
          permission: "PERM_MODIFY_SETTINGS"
        },
        name: "Settings"
      }]
    }
  },
  methods: {
    changeHomepage(current) {
      this.$emit('change-homepage', this.role, current)
    }
  },
  props: {
    selectModel: Object,
    role: String
  },
  created() {
    this.selected = this.selectModel
  }
}
</script>

<style lang="css" scoped>
.custom-select:focus {
    border-color: var(--viridian);
    outline: 0;
    box-shadow: none;
}
</style>