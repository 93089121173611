<template>
  <div class="container-base section-container-padding no-padding-left bg-white row ml-0 pl-0">
    <settings-tabs tab-name="user-admin-settings" class="col-2 pl-0 pr-0"/>
    <div class="myproj-container settings-my-instances-tabs col-10">
      <b-row>
        <b-tabs v-model="step" class="col-12" content-class="mt-3">
          <b-tab @click="goToUsersTab">
            <template v-slot:title>
              <div>
                <img src="/assets/images/icon_myproject_configure.svg"/>
                <span>Users</span>
              </div>
            </template>
            <div class="col-12 margin-cards">
              <div>
                <p class="text-align-left openSans-400 fz-15">Search <b class="openSans-600 fz-15">{{ totalUsers }}</b> Users</p>
              </div>

              <b-row>
                <b-col lg="6" class="my-1 ta-left">
                  <b-form-group
                    label="Filter"
                    label-for="filter-input"
                    label-cols-sm="2"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Type to Search"
                      ></b-form-input>

                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-col lg="6" class="my-1 ta-left px-0">
                <b-form-group
                  v-model="sortDirection"
                  label="Filter On"
                  description="Leave all unchecked to filter on all data"
                  label-cols-sm="2"
                  label-size="sm"
                  class="mb-0"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-checkbox-group
                    v-model="filterOn"
                    :aria-describedby="ariaDescribedby"
                    class="mt-1"
                  >
                    <b-form-checkbox value="full-name">Name</b-form-checkbox>
                    <b-form-checkbox value="role">Role</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col>

              <br>
              <div>
                <b-table :items="users" :fields="fields" striped responsive="sm"
                         :filter="filter"
                         :filter-included-fields="filterOn"
                         :sort-direction="sortDirection">
                  <template #cell(status)="row">
                    <b-img :src="`/assets/images/${npLockBlue}`" v-if="row.item.passhash2"/>
                    <b-img :src="`/assets/images/${npLockYellow}`" v-else-if="row.item.passhash2 || row.item.logincode"/>
                    <b-img :src="`/assets/images/${npLockRed}`" v-else/>
                  </template>
                  <template  #cell(actions)="row">
                    <b-icon-pencil-square v-b-modal="`modal-${row.item.index}`" @click="editUser(row.item)" class="cursor-pointer"/>
                  </template>

                </b-table>
              </div>

            </div>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <div v-if="!editionData.editing">
                <img src="/assets/images/icon_add.svg"/>
                <span>Add New</span>
              </div>
              <div v-else>
                <img src="/assets/images/icon_edit.svg"/>
                <span>Edit User</span>
              </div>
            </template>
            <create-edit-user :user="editionData.user" :option-roles="editionData.roles"
                              :editing="editionData.editing" :callback="goToUsersTab" />
          </b-tab>
        </b-tabs>
      </b-row>
    </div>
  </div>
</template>

<script>
import SettingsService from "@/modules/settings/services/SettingsService.js"
import store from '@/store'
import SettingsTabs from "@/modules/settings/components/SettingsTabs";

import CreateEditUser from "@/modules/settings/components/CreateEditUser.vue"

export default {
  name: "TabUserAdminSettings",
  components: { CreateEditUser, SettingsTabs },
  data() {
    return {
      fields: [
        {key: 'full-name', label: 'Name', sortDirection: 'desc'},
        {key: 'email', label: 'Email Address'},
        {key: 'role', label: 'Role'},
        {key: 'status', label: 'Status'},
        {key: 'phone-number', label: 'Phone Number'},
        {key: 'actions', label: 'Actions'}
      ],
      users: [],
      editionData: {
        editing: false,
        roles: [],
        user: null
      },
      totalUsers: 0,
      npLockRed: 'np-lock-717607-1-d-8755-copy-2.svg',
      npLockYellow: 'np-lock-717607-1-d-8755-copy-4.svg',
      npLockBlue: 'np-lock-717607-1-d-8755.svg',
      tabCounter: 0,
      step: 0,
      filter: null,
      filterOn: [],
      sortDirection: 'asc',
    }
  },
  methods: {
    searchUsers() {
      SettingsService.getUsers().then(response => {
        this.users = response.data.records
        this.totalUsers = response.data.total
      })
      .catch((err) => {
        console.log(err)
      })
    },
    goToUsersTab() {
      this.editionData.editing = false
      this.editionData.user = null
      this.step = 0
      this.searchUsers()
    },
    editUser(user) {
      this.editionData.user = user
      this.editionData.editing = true
      this.step = 1
    },
    getRoles() {
      SettingsService.getRolesAndPermissions().then(response => {
        this.editionData.roles = Object.keys(response.data['roles']).map(role => {
          return { value: role, text: role.toUpperCase() }
        })
      })
    }
  },
  computed: {
    currentInstance: () => store.getters.getInstance
  },
  watch: {
    currentInstance() {
      this.searchUsers()
      this.getRoles()
    }
  },
  created() {
    if (this.$store.getters.hasSelectedInstance) {
      this.searchUsers()
      this.getRoles()
      this.step = this.$route.query.step*1
    }
  }
}
</script>

