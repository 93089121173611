<template>
    <div class="col-4">
        <b-card
        class="percent-card tile-card"
        header-text-variant="white"
        header-tag="header"
        header-bg-variant="dark"
        v-bind:class="{'tile-card-suggested' : card_suggested}"
        >
            <img :src="`/assets/images/${card_icon}`">
            <b-card-title class="tile-card-title openSans-100 fz-23">
                {{ $t(card_title) }}
            </b-card-title>
            <b-card-text class="title-card-text openSans-100 ta-center fz-16 cards-description">
                {{ $t(card_description) }}
            </b-card-text>

            <b-button :disabled="!card_route"  class="openSans-600 fz-12 ta-center button-secondary" @click="cardRedirection(card_route)" size="md">
              {{ $t('proyect.create.proyectCardButton') }}
            </b-button>
        </b-card>
    </div>
</template>

<script>
export default {
  name: 'CreateFirstProyectCard',
  props: {
      card_title: String,
      card_icon: String,
      card_description: String,
      card_suggested: Boolean,
      card_route: String,
  },
  methods: {
      cardRedirection(route){
        this.$router.push('/' + this.$store.getters.getInstance + route)
      }
  },
}
</script>

<style scoped>
.cards-description{
    min-height: 48px;
}
</style>
