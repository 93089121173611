<template>
  <div class="container-base section-container-padding no-padding-left bg-white row ml-0 pl-0">
    <settings-tabs tab-name="roles-and-permission" class="col-2 pl-0 pr-0"/>
    <div class="col-10"> 
      
      <div class="myproj-container settings-my-instances-tabs bg-white ">
        <b-row>
          <b-tabs class="col-12" content-class="mt-3">
            <b-tab class="roles-permission-padding">
              <template v-slot:title>
                <img :src="'/assets/images/icon_avatar.svg'"/><span>{{ $t('settings.menu.tab.rolesAndPermission.title') }}</span>
              </template>
              <div class="col-12 margin-cards">
                <b-alert
                  class="text-left"
                  :show="dismissCountDown" 
                  dismissible
                  variant="success"
                  @dismissed="dismissCountDown=0"
                  @dismiss-count-down="countDownChanged">
                  {{ $t('settings.labels.successMessage') }}
                </b-alert>
                <b-row class="my-4 mr-0">
                  <!-- <b-col align-self="start" class="text-left">
                    Instance:
                  </b-col> -->
                  <b-col align-self="end" class="text-right pr-0">
                    <b-link v-b-modal.add-new-role>
                      <img src="/assets/images/icon_add.svg">
                      <span class="fz-15 ml-2 openSans-400 txt-viridian add-new-role-button-adjustment">{{ $t('settings.menu.buttons.addNewRole') }}</span>
                    </b-link>
                  </b-col>
                </b-row>
                <b-row class="margin-rows">
                  <b-col>
                    <b-table-simple class="roles-permission-table" responsive>
                      <b-thead>
                        <b-tr>
                          <b-th class="text-left settings-table-header fz-15 openSans-400">{{ $t('settings.labels.permission')}}</b-th>
                          <b-th v-for="role in roles" v-bind:key="role" class="text-left settings-table-header fz-15 openSans-400">
                            {{ capitalize(role) }}
                            <b-link v-if="!defaultRoles.includes(role)" @click="deleteRole(role)">
                              <img src="/assets/images/icon_remove.svg">
                            </b-link>
                          </b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr v-for="item in items" v-bind:key="item.key">
                          <b-td class="text-left fz-15 openSans-100">{{ $t(item.permission) }}</b-td>
                          <b-td v-for="role in roles" v-bind:key="role" class="text-left">
                            <b-form-checkbox size="lg" v-model="item[role]" />
                          </b-td>
                        </b-tr>
                      </b-tbody>
                      <b-tfoot>
                        <b-tr>
                          <b-th class="text-left settings-table-header fz-15 openSans-400">{{ $t('settings.labels.homepageDefaultView') }}</b-th>
                          <b-th v-for="role in roles" v-bind:key="role" class="text-left settings-table-header fz-15 openSans-400">
                            {{ capitalize(role) }}
                          </b-th>
                        </b-tr>
                        <b-tr>
                          <b-td class="text-left fz-15 openSans-100">{{ $t('settings.labels.defaultView') }}</b-td>
                          <b-td v-for="role in Object.keys(homepages)" v-bind:key="role" class="text-left">
                            <home-page-select :select-model="homepages[role]" :role="role" v-on:change-homepage="changeHomepage" />
                          </b-td>
                        </b-tr>
                      </b-tfoot>
                    </b-table-simple>
                  </b-col>
                </b-row>
                <b-row class="margin-rows">
                  <b-col>
                    <div class="text-left">
                      <b-button class="openSans-600 fz-12 ta-center button-primary mr-100 " size="sm" @click="updateSettings()">
                          <span class="mx-1">{{ $t('settings.menu.buttons.updateSettings') }}</span>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
          </b-tabs>
        </b-row>

        <b-modal id="add-new-role" ref="modal" :title="$t('settings.labels.defaultView')" @show="resetModal" @hidden="resetModal" @ok="handleOk">
          <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Role Name" label-for="name-input" invalid-feedback="Role name is required" :state="nameState">
              <b-form-input id="name-input" v-model="newRoleName" :state="nameState" required></b-form-input>
            </b-form-group>
          </form>
        </b-modal>

      </div>
    </div>
    
  </div>
</template>

<script>
import SettingsService from '@/modules/settings/services/SettingsService.js'
import HomePageSelect from '@/modules/settings/components/HomePageSelect.vue'
import SettingsTabs from "@/modules/settings/components/SettingsTabs";

import store from '@/store'
import { SET_CURRENT_INSTANCE } from "@/modules/security/store/actions/PermissionsActions";
import { GET_USER } from "@/modules/security/store/actions/UserActions";

export default {

  name: 'RolesAndPermission',
  components: {
    HomePageSelect, SettingsTabs
  },
  data() {
    return {
      dismissSecs: 7,
      dismissCountDown: 0,
      newRoleName: '',
      nameState: null,
      defaultRoles: ['admin', 'expert', 'user'],
      roles: ['admin', 'expert', 'user'],
      items: [],
      homepages: {}
    }
  },
  methods: {
    initRolesAndPermissions() {
      SettingsService.getRolesAndPermissions().then(response => {
        let permissions = response.data['all_permissions']
        let roles = response.data['roles']
        var items = []
        Object.keys(permissions).forEach(permissionKey => {
          var permissionObj = {
            key: permissionKey,
            permission: permissions[permissionKey].name
          }
          Object.keys(roles).forEach(role => {
            permissionObj[role] = roles[role].permissions.includes(permissionKey)
            if (!this.roles.includes(role)) {
              this.roles.push(role)
            }
          })
          items.push(permissionObj)
        })
        Object.keys(roles).forEach(role => {
          this.homepages[role] = roles[role].homepage
        })
        this.items = items
      })  
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    capitalize(sting) {
      return sting.charAt(0).toUpperCase() + sting.slice(1)
    },
    changeHomepage(role, data) {
      this.homepages[role] = data
    },
    addRole() {
      this.roles.push(this.newRoleName)
      this.homepages[this.newRoleName] = {
        name: "Default",
        url: "",
        permission: ""
      }
      this.items.forEach(item => {
        item[this.newRoleName] = false
      })
    },
    deleteRole(role) {
      const index = this.roles.indexOf(role);
      if (index > -1) {
        this.roles.splice(index, 1);
      }
      this.items.forEach(item => {
        delete item[role]
      })
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.newRoleName = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }
      this.addRole()
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('add-new-role')
      })
    },
    updateSettings() {
      var rolesAndPermission = {}
      this.roles.forEach(role => {
        rolesAndPermission[role] = {
          homepage: this.homepages[role],
          permissions: []
        }
      })
      this.items.forEach(item => {
        this.roles.forEach(role => {
          if(item[role]) {
            rolesAndPermission[role]['permissions'].push(item.key)
          }
        })
      })
      SettingsService.saveRolesAndPermissions(rolesAndPermission).then(() => {
        store.dispatch(GET_USER).then(() => {
          store.dispatch(SET_CURRENT_INSTANCE, { instanceName: this.$route.params.bot })
          this.showAlert()
        })
      })
    }
  },
  computed: {
    currentInstance: () => store.getters.getInstance
  },
  watch: {
    currentInstance() {
      this.initRolesAndPermissions()
    }
  },
  created() {
    if (this.$store.getters.hasSelectedInstance) {
      this.initRolesAndPermissions()
    }
  }
}
</script>

<style lang="css">

    .roles-permission-table .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: var(--viridian);
    background-color: var(--viridian);
  }

  .roles-permission-table .custom-checkbox.b-custom-control-lg .custom-control-label::before, .input-group-lg .custom-checkbox .custom-control-label::before {
    border-radius: 2px;
  }

  .add-new-role-button-adjustment {
    vertical-align: bottom;
    margin-left: 12px !important; 
  }

  .settings-table-header {
    color: #09609f;
    border-top: none !important;
  }

  .roles-permission-padding {
    padding: 0px 31px 0px 15px;
  }
</style>