<template>
  <div class="container-base section-container-padding no-padding-left bg-white row ml-0 pl-0">
    <settings-tabs tab-name="utility-sets" class="col-2 pl-0 pr-0"/>
    <div class="col-10 mt-3">
      <b-alert
        class="text-left"
        :show="dismissCountDown"
        dismissible
        variant="success"
        @dismissed="dismissCountDown=0"
        @dismiss-count-down="countDownChanged">
        {{ $t('settings.labels.successMessage') }}
      </b-alert>

      <b-row class="d-flex">
        <div class="li-utility-set-container">

          <br>
          <b-row>
            <b-col>
              <div style="text-align:left; padding-bottom:11px; border-bottom: 1px solid #d3d3d3; width: 100%;">
                <span class="ontology-sections-title">Utility Sets</span>
              </div>
            </b-col>
          </b-row>
          <div class="text-left">
            <br>
            <b-form-group>
              <template #label>
                <b-form-checkbox v-model="utilitySets.status" :indeterminate="utilitySets.indeterminate" @change="utilitySets.toggleAll">
                  All
                </b-form-checkbox>
              </template>

              <b-col cols="5">
              <template>
                <b-form-checkbox v-for="utility in utilitySets.utilities"
                                 v-model="selectedUtilities"
                                 :value="utility.name"
                                 :key="utility.name"
                                 @change="utilitySets.markedPartial"
                                 stacked>
                  {{ $t(utility.text)}}
                </b-form-checkbox>
              </template>
                </b-col>
            </b-form-group>
          </div>
          <b-row class="li-header-initial-row text-left">
            <b-col cols="12">
              <div class="d-flex">
                <b-button class="button-primary" @click="configureUtilitySets()">Save</b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-row>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import SettingsService from "@/modules/settings/services/SettingsService";
import {mapState} from 'vuex'
import UtilitySetsService from "@/modules/utilitySets/services/UtilitySetsService";
import SettingsTabs from "@/modules/settings/components/SettingsTabs";


export default {
  name: 'tab-utility-sets',
  components: {SettingsTabs},
  data() {
    return {
      selected: this.$router.currentRoute.params.bot,
      bots: store.getters.getCurrentUser.bots,
      bot: {},
      name: null,
      checked: null,
      currentUser: store.getters.getCurrentUser.userid,
      selectedUtilities: [],
      utilitySets: {
        description: 'Enrich Document',
        indeterminate: false,
        name: 'enrich_document',
        utilities: [],
        toggleAll: (id) => id,
        status: false
      },
      dismissSecs: 7,
      dismissCountDown: 0,
    }
  },
  methods: {
    getBot() {
      SettingsService.getBot(this.currentBot).then(response => {
        this.bot = response.data
      })
    },
    getUtilities() {
      UtilitySetsService.getUtilities(this.currentBot).then(response => {
        response.data.default_utilities.forEach(utility => {
          this.utilitySets.utilities.push({
            name: Object.keys(utility)[0],
            text: utility[Object.keys(utility)[0]].title
          })
        })
        this.utilitySets.toggleAll = checked => {
          if (checked) {
            this.utilitySets.utilities.forEach(utility => {
              this.selectedUtilities.push(utility.name)
            })
          } else {
            this.utilitySets.utilities.forEach(ontology => {
              const index = this.selectedUtilities.indexOf(ontology.name)
              if (index > -1) {
                this.selectedUtilities.splice(index, 1)
              }
            })
          }
          this.utilitySets.status = checked
        }
        this.utilitySets.markedPartial = () => {
          response.data.utilities.utilities.forEach(currentUtility => {
            this.selectedUtilities.includes(currentUtility)
          })
        }
        this.selectedUtilities = response.data.utilities.utilities
        this.utilitySets.status = this.utilitySets.utilities.length === this.selectedUtilities.length
      })
    },
    configureUtilitySets() {
      this.bot.utilities = this.selectedUtilities
      let botSettings = {
        'utilities': [...new Set(this.bot.utilities)]
      }
      SettingsService.saveUtilities(botSettings, this.selected).then(() => {
        this.showAlert()
        this.getBot()
      })
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    }
  },
  watch: {
    currentBot() {
      this.getUtilities()
      this.getBot()
    }
  },
  computed: mapState({
    currentBot: () => store.getters.getInstance
  }),
  created() {
    if (this.$store.getters.hasSelectedInstance) {
      this.getBot()
      this.getUtilities()
    }
  }}
</script>
<style lang="scss" scoped>

.button-secondary svg {
  margin-right: 0.25rem !important;
}

div.utility-card-icon-border-box img {
  margin: 0 auto;
}

.padding-row {
  padding-left: 15px;
}

.margin-btn {
  margin-left: 30px;
}

.margin-info {
  margin-left: 10px;
}

.align-btn {
  display: block;
}

.row-top{
  margin-top: 22px;
}

.info-color-letter {
  color: #707070
}

h3.settings-div-line {
    color: var(--dark-grey);
    display: flex;
    align-items: center;
    justify-content: center;
}

h3.settings-div-line span {
    background: #fff;
    margin: 0 0;
}

h3.settings-div-line:after {
    background: #dadada;
    margin-left:20px;
    height: 2px;
    flex: 1;
    content: '';
}

.instance-image-display {
    min-width: 108px;
    min-height: 108px;
    border: 1px solid #ebebeb;
    background-color: #acacac;
    border-radius: 10px;
    height: 108px;
    width: 108px;
}

.warning-msg-modal {
    font-family: OpenSans;
    font-size: 25px;
    color: #e90000;
    margin: 10px 0px
}

a.button-primary {
  display:flex;
  max-width:140px;
}

a.button-primary span {
  margin: auto !important;
}

table td span {
  vertical-align:middle;
  margin-left:14px;
}

.table-continue-container {
  text-align:left;
}

.button-pipelines-table {
  width: 94px;
  min-width: 94px;
  height: 34px;
  font-size: 12px;
}

.table-pipelinescol-item {
  font-size:15px;
  margin: auto 0px auto 16px;
}

th {
  font-family: OpenSans;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #151515;
}
.table th {
  border-top:none;
}
.table td {
  border-bottom:1px solid #d3d3d3;
  vertical-align: middle;
}
.myproj-ontology-configure-label {
  font-family: OpenSans;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #151515;
}
.myproj-ontology-select-title {
  font-family: OpenSans;
  font-size: 28px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #151515;
  margin-right:30px;
}

.custom-control-label span, span.cursor-pointer {
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--viridian);
}

.custom-control-label::before {
  border:1px solid var(--viridian);
  border-radius: 6px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-color: var(--viridian);
  border-radius: 6px;
}

.custom-control-input:checked + label.custom-control-label span,
.custom-control.custom-checkbox .custom-control-input:checked + span.cursor-pointer {
  color:black;
}

.ontology-sections-title {
  font-family: OpenSans;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #151515;
  margin-bottom:11px;
}

div.d-inline-flex:first {
    border-top: 1px solid #d3d3d3;
}

div.d-inline-flex {
  border-bottom: 1px solid #d3d3d3;
  padding: 20px 0px;
  min-height: 100%;
  min-width: 100%;
  align-items: center;
}

div.d-inline-flex {
  border-bottom: 1px solid #d3d3d3;
  min-height: 100%;
  min-width: 100%;
  align-items: center;
}

fieldset {
  border-bottom: 1px solid #d3d3d3;
  padding: 20px 0px;
  min-height: 100%;
  min-width: 100%;
  align-items: center;
}

.form-group {
  margin-bottom:0px;
}
</style>