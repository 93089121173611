<template>
<b-overlay :show="showOverlay" rounded="sm">
  <b-col>
    <b-row>
      <dismissible-alert :trigger="alerts.user.success" :message="'User saved successfully'" variant="success"/>
      <dismissible-alert :trigger="alerts.user.error" :message="alerts.user.errorMsg"
                         variant="danger" :seconds="8"/>

    </b-row> <!-- TODO: The first row of this tab always is longest than the rest, fix with css maybe? -->
    <b-row>
      <div class="col-4">
        <b-form-group class="text-align-left openSans-400 fz-15" :label="$t('settings.menu.tab.userAdminSettings.createEditUser.firstName.label') + ' *'">

          <b-form-input class="openSans-100 fz-18" :placeholder="$t('settings.menu.tab.userAdminSettings.createEditUser.firstName.placeholder')"
                        v-model="currentUser.firstName" required></b-form-input>
        </b-form-group>
      </div>
      <div class="col-4">
        <b-form-group class="text-align-left openSans-400 fz-15" :label="$t('settings.menu.tab.userAdminSettings.createEditUser.lastName.label') + ' *'">
          <b-form-input class="openSans-100 fz-18" :placeholder="$t('settings.menu.tab.userAdminSettings.createEditUser.firstName.placeholder')"
                        v-model="currentUser.lastName" required></b-form-input>
        </b-form-group>
      </div>
    </b-row>

    <b-row>
      <div class="col-4">
        <b-form-group class="text-align-left openSans-400 fz-15" :label="$t('settings.menu.tab.userAdminSettings.createEditUser.email.label') + ' *'">
          <b-form-input class="openSans-100 fz-18" type="email" :placeholder="$t('settings.menu.tab.userAdminSettings.createEditUser.email.placeholder')"
                        v-model="currentUser.email" :disabled="this.editing" required></b-form-input>
        </b-form-group>
        <p class="text-left-display openSans-400 fz-11">* {{ $t('settings.menu.tab.userAdminSettings.createEditUser.email.message') }}</p>
      </div>
      <div class="col-4">
        <label class="text-left-display openSans-400 fz-15">{{ $t('settings.menu.tab.userAdminSettings.createEditUser.phoneNumber.label') }}</label>
        <vue-phone-number-input class="openSans-100 fz-18" v-model="currentUser.phoneNumber" @update="result = $event" clearable/>
      </div>
    </b-row>

    <b-row>
      <div class="col-4">
        <b-form-group class="text-align-left openSans-400 fz-15" :label="$t('settings.menu.tab.userAdminSettings.createEditUser.title.label')">
          <b-form-input class="openSans-100 fz-18" :placeholder="$t('settings.menu.tab.userAdminSettings.createEditUser.title.placeholder')"
                        v-model="currentUser.title" required></b-form-input>
        </b-form-group>
      </div>

      <div class="col-4">
        <label class="text-left-display openSans-400 fz-15">{{ $t('settings.menu.tab.userAdminSettings.createEditUser.role.label') }}</label>
        <b-form-select class="role-select w-100 openSans-300 fz-15" v-model="currentUser.role" :options="optionRoles" required>
          <template #first>
            <b-form-select-option :value="null" disabled>-- {{ $t('settings.menu.tab.userAdminSettings.createEditUser.role.placeholder') }} --</b-form-select-option>
          </template>
        </b-form-select>
      </div>
    </b-row>

    <b-row class="margin-between mt-4">
      <b-button class="openSans-300 fz-12 button-primary" size="sm" @click="save">
        {{ $t('general.buttons.saveChanges') }}
      </b-button>
      <b-button class="openSans-300 fz-12 button-secondary margin-btn" @click="cancel">
        {{ $t('general.buttons.cancel') }}
      </b-button>
      <b-button class="openSans-300 fz-12 button-secondary margin-btn" @click="deleteUser" v-if="editing">
        {{ $t('settings.menu.tab.userAdminSettings.createEditUser.buttons.delete') }}
      </b-button>
    </b-row>

  </b-col>
</b-overlay>
</template>

<script>
import SettingsService from "@/modules/settings/services/SettingsService.js"
import DismissibleAlert from "@/modules/common/components/DismissibleAlert";

export default {

  name: 'CreateEditUser',
  components: {DismissibleAlert},

  props: {
    user: Object,
    optionRoles: Array,
    editing: Boolean,
    callback: Function
  },
  data () {
    return {
      currentUser: null,
      showOverlay:false,
      alerts: {
        user: {
          success: false,
          error: false,
          errorMsg: ''
        }
      }
    }
  },
  methods: {
    save() {
      if (!this.validate()) {
        return false
      }
      this.showOverlay = true
      // this.currentUser.phoneNumber = this.result.formatNational
      let userData = {
        'first-name': this.currentUser.firstName,
        'last-name': this.currentUser.lastName,
        'email': this.currentUser.email,
        'phone-number': this.currentUser.phoneNumber,
        'title': this.currentUser.title,
        'role': this.currentUser.role
      }
      if (this.editing) {
        userData['userid'] = this.currentUser.userid
        userData['authzero_id'] = this.currentUser.authzeroId
      }
      SettingsService.editUser(userData).then(res => {
          if(res.data.status_code === 500){
            throw new Error(res.data.message)
          }
          this.showOverlay = false;
          this.callback();
      }
      ).catch(error => {
        console.log(error)
        this.showErrorAlert(error.message)
        this.showOverlay = false
      })
    },
    validate() {
      let validationResults = []
      validationResults.push(this.validateEmail())
      validationResults.push(this.validateRole())
      let error = false
      let msg = ""
      validationResults.forEach(validationResult => {
        if (!validationResult.isValid) {
          error = true
          msg += validationResult.errorMsg + "<br/>"
        }
      })
      if (error) {
        this.showErrorAlert(msg)
        return false
      } else {
        return true
      }
    },
    validateRole() {
      let result = { isValid: true, errorMsg: '' }
      if (this.currentUser.role == null) {
        result.isValid = false
        result.errorMsg = "No role selected."
      }
      return result
    },
    validateEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let valid = re.test(String(this.currentUser.email).toLowerCase());
      let error = ''
      if (!valid) {
        error = "Not a valid email."
      }
      return { isValid: valid, errorMsg: error }
    },
    showErrorAlert(message) {
      this.alerts.user.errorMsg = message
      this.alerts.user.error = !this.alerts.user.error
    },
    cancel() {
      this.callback()
    },
    deleteUser() {
      SettingsService.deleteUser(this.currentUser.authzeroId).then(this.callback).catch(error => {
        console.log(error)
      })
    }
  },
  watch: {
    user() {
      if (this.user) {
        this.currentUser = {
          userid: this.user['userid'],
          firstName: this.user['first-name'],
          lastName: this.user['last-name'],
          email: this.user['email'],
          phoneNumber: this.user['phone-number'],
          title: this.user['title'],
          role: this.user['role'],
          authzeroId: this.user['authzero_id']
        }
      } else {
        this.currentUser = {
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: null,
          title: '',
          role: null
        }
      }
    }
  },
  created() {
    this.currentUser = {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: null,
      title: '',
      role: null
    }
  }
}
</script>

<style lang="css" scoped>
  .margin-between {
    padding-left: 15px;
  }

  .margin-btn {
    margin-left: 15px;
  }

  .text-left-display {
    text-align: left!important;
    display: block;
  }

  .role-select {
    width: 40%;
    color: #28a745;
    border-color: #28a745;
  }
</style>