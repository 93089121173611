<template>
  <b-container class="bv-example-row col-12">
    <div class="text-left">
      <h3 class="section-title-right-line openSans-100 fz-23">{{$t(title)}}
      </h3>
    </div>
    <b-row>
      <feature-card
        v-for="item in filtersByButton" :key="item.title"
        :utility-set="item.util"
        :button-label="$t('utilitySets.install')"
        :show-button-icon="true"></feature-card>
    </b-row>
  </b-container>
</template>
<script>
import FeatureCard from "@/modules/utilitySets/components/FeatureCard"

export default {
  name: 'list-utility',
  components: { FeatureCard },
  props: {
    utility_sets: {},
    show_title_utility: Boolean,
    title: String,
    selectedSet: String,
    defaultSet: String
  },
  computed: {
    filtersByButton() {
      let set = this.selectedSet;
      if (this.show_title_utility) {
        set = 'All'
      }
      if (set == 'All') {
        return this.utility_sets
      } else {
        return this.utility_sets.filter(function (util) {
          return util.util.set == set
        })
      }
    }
  }
}
</script>
