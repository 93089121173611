<template>
  <b-row class="utility-button-group">
    <b-col offset="1" cols="12">
      <div>
        <b-button-toolbar>
          <b-button-group class="mx-1">
            <button class="secondary-empty openSans-600 fz-12" :class="{'button-border-utility': selected == 'Featured' }" @click="changeSet($t('utilitySets.buttonGroup.featured'))">
              {{ $t('utilitySets.buttonGroup.allfeatured') }}
            </button>
          </b-button-group>
          <b-button-group class="mx-1">
            <button class="secondary-empty openSans-600 fz-12" :class="{'button-border-utility': selected == 'Insurance'}"  @click="changeSet($t('utilitySets.buttonGroup.insurance'))">
              {{ $t('utilitySets.buttonGroup.insurance') }}
            </button>
          </b-button-group>
          <b-button-group class="mx-1">
            <button class="secondary-empty openSans-600 fz-12" :class="{'button-border-utility': selected == 'Life Sciences'}" @click="changeSet($t('utilitySets.buttonGroup.lifesciences'))">
              {{ $t('utilitySets.buttonGroup.lifesciences') }}
            </button>
          </b-button-group>
          <b-button-group class="mx-1">
            <button class="secondary-empty openSans-600 fz-12" :class="{'button-border-utility': selected == 'Enterprise'}"  @click="changeSet($t('utilitySets.buttonGroup.enterprise'))">
              {{ $t('utilitySets.buttonGroup.enterprise') }}
            </button>
          </b-button-group>
        </b-button-toolbar>
      </div>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: 'button-group-utility',
  props: {
    selectedSet: String
  },
  data (){
    return {
        selected: 'Featured'
    }
  },
  methods: {
    changeSet(set) {
      this.selected = set
      this.$emit('selected-set', this.selected)
    }
  }
}
</script>
<style scoped>

</style>