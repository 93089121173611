<template>
  <b-modal class="upload-file-modal" :id="id" hide-footer hide-header>
    <preloader v-if="useLoader" :type="'modal'" />
    <div class="d-block text-center m-3" v-if="!useLoader">
      <img :src="`/assets/images/${iconName}`">
      <h3 class="my-3 openSans-100 fz-24">{{ title }}</h3>
      <b-form-file
        multiple
        size="lg"
        v-model="files"
        :accept="accept"
        :placeholder="$t('ingestionProject.cards.card.uploadFile.placeholder')"
        :drop-placeholder="$t('ingestionProject.cards.card.uploadFile.dropPlaceholder')"
        @input="submitFile"></b-form-file>
    </div>
  </b-modal>
</template>

<script>
import Preloader from '@/modules/common/components/Preloader.vue'

export default {
  name: 'UploadFile',
  components: { Preloader },
  data () {
    return {
      files: [],
      useLoader: false,
    }
  },
  props: {
    id: String,
    iconName: String,
    title: String,
    accept: String,
    apiRequest: Function,
    callback: Function
  },
  methods: {
    hideModal() {
      this.$bvModal.hide(this.id)
    },
    submitFile() {
      this.useLoader = true
      var formData = new FormData()
      this.files.forEach(file => {
        formData.append('files', file, file.name)
      })
      this.apiRequest(formData).then(response => {
        if (this.callback) {
          this.callback(response)
        }
        this.hideModal()
        this.useLoader = false;
      }).catch((error) => {
        console.log(error)
      })
    }
  }

}
</script>

<style lang="css" scoped>
</style>