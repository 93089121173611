<template>
    <div class="container-base section-container-padding no-padding-left bg-white row ml-0 pl-0" >
    <settings-tabs tab-name="my-profile-settings" class="col-2 pl-0 pr-0"/>
    <div class="myproj-container settings-my-instances-tabs col-10">
        <b-row>
            <b-tabs class="col-12" content-class="mt-3">
                <b-tab>
                    <template v-slot:title>
                        <img :src="'/assets/images/icon_instances.svg'"/><span>
                          {{ $t('settings.menu.tab.myProfile.profileInfo') }}
                        </span>
                    </template>
                    <div class="col-12 margin-cards">
                        <dismissible-alert :trigger="alers.saveProfileSettings" :message="$t('settings.labels.settingsUpdateSuccess')" variant="success"/>
                        <b-row class="margin-rows">
                            <b-col>
                                <span class="fz-15 openSans-400 settings-input-label">{{ $t('settings.menu.tab.myProfile.displayName') }}</span>
                                <b-form-input v-model="userSettings.displayName" class="settings-input openSans-100 fz-18" placeholder="Enter your display name"></b-form-input>
                            </b-col>
                            <b-col>
                                <span class="fz-15 openSans-400 settings-input-label">{{ $t('settings.menu.tab.myProfile.fullName') }}</span>
                                <b-form-input v-model="userSettings.fullName" class="settings-input openSans-100 fz-18" placeholder="Enter your full name"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="margin-rows">
                            <b-col>
                                <span class="fz-15 openSans-400 settings-input-label">{{ $t('settings.menu.tab.myProfile.workEmailAdress') }}*</span>
                                <b-form-input v-model="userSettings.email" class="settings-input openSans-100 fz-18" placeholder="Enter your work email"></b-form-input>
                                <span class="fz-11 openSans-400 settings-input-legend">*{{ $t('settings.menu.tab.myProfile.validEmailDisclaimer')}}</span>
                            </b-col>
                            <b-col>
                                <span class="fz-15 openSans-400 settings-input-label">{{ $t('settings.menu.tab.myProfile.phoneNumber') }}</span>
                                  <vue-tel-input v-model="userSettings.phoneNumber" class="settings-input openSans-100 fz-18"></vue-tel-input>
                                <span class="fz-11 openSans-400 settings-input-legend">{{ $t('settings.menu.tab.myProfile.phoneNumberDisclaimer') }}<br>{{ $t('settings.menu.tab.myProfile.phoneNumberDisclaimer2') }}</span>
                            </b-col>
                        </b-row>
                        <b-row v-if="hasPermission('PERM_DISPLAY_API_KEY')" class="margin-rows">
                            <b-col>
                                <span class="fz-15 openSans-400 settings-input-label">{{ $t('settings.menu.tab.myProfile.apiKey') }}</span>
                                <b-form-input v-model="userSettings.apiKey" disabled class="settings-input openSans-100 fz-18" ></b-form-input>
                            </b-col>
                            <b-col></b-col>
                        </b-row>
                        <b-button class="openSans-600 fz-12 ta-center button-primary mr-100 f-left" size="sm" @click="updateUserData">
                            <span class="mx-1">{{ $t('settings.menu.tab.myProfile.update') }}</span>
                        </b-button>
                        <div class="profile-image-div">
                           <div>
                               <h3 class="fz-15 openSans-400 settings-input-label f-left">{{ $t('settings.menu.tab.myProfile.profileImage') }}</h3>
                            </div> 
                            <div>
                                <span class="fz-12 openSans-400 image-size-requeriment-label f-left">{{ $t('settings.menu.tab.myProfile.imageRequeriment') }}</span>
                            </div>
                            <div class="d-flex justify-content-start f-left">
                                <b-img v-if="userSettings.avatarUrl" class="profile-image-display" :src="userSettings.avatarUrl"></b-img>
                                <b-img v-else class="profile-image-display" v-bind="mainProps"></b-img>
                                <b-button v-b-modal.upload-profile-picture class="openSans-600 fz-12 ta-center button-secondary mr-buttons-profile-pick " size="sm">
                                    <span class="mx-1">{{ $t('settings.menu.tab.myProfile.uploadNew') }}</span>
                                </b-button>
                                <b-button class="openSans-600 fz-12 ta-center button-secondary mr-buttons-profile-pick  button-no-border" size="sm"
                                          @click="deleteProfile">
                                    <span class="mx-1">{{ $t('settings.menu.tab.myProfile.delete') }}</span>
                                </b-button>
                                <upload-file-modal
                                    id="upload-profile-picture"
                                    :title="$t('settings.menu.tab.myProfile.uploadProfilePicturePopup')"
                                    icon-name="icon_cip_api.svg"
                                    accept="image/*"
                                    :api-request="uploadProfilePicture"
                                    :callback="uploadProfilePictureCallback"/>
                            </div>
                        </div>
                    </div>
                </b-tab>
                <b-tab >
                    <template v-slot:title>
                        <img :src="'/assets/images/icon_myproject_configure.svg'"/>
                        <span>{{ $t('settings.menu.tab.myProfile.notificationSettings') }}</span>
                    </template>
                    <div class="col-12 margin-cards">
                        <dismissible-alert :trigger="alers.saveNotificationSettings" :message="$t('settings.labels.notificationSettingsUpdateSuccess')" variant="success"/>
                        <b-row class="margin-rows">
                            <b-col>
                                <span class="fz-15 openSans-400 settings-input-label">{{ $t('settings.menu.tab.myProfile.notificationType') }}</span>
                                <b-form-select v-model="userSettings.notification" :options="options.notification" class="btn-secondary-myproj navbar-projects settings-input f-left"></b-form-select>
                            </b-col>
                            <b-col>
                                <span class="fz-15 openSans-400 settings-input-label">{{ $t('settings.menu.tab.myProfile.notificationFrequency') }}</span>
                                <b-form-select v-model="userSettings.notificationFrequency" :options="options.notificationFrequency" class="btn-secondary-myproj navbar-projects settings-input f-left"></b-form-select>
                            </b-col>
                        </b-row>
                        <b-button class="openSans-600 fz-12 ta-center button-primary mr-100 " size="sm" @click="updateNotificationSettings">
                            <span class="mx-1">{{ $t('settings.menu.tab.myProfile.update') }}</span>
                        </b-button>
                    </div>
                </b-tab>
                <b-tab>
                    <template v-slot:title>
                        <img :src="'/assets/images/icon_myproject_configure.svg'"/>
                        <span>{{ $t('settings.menu.tab.myProfile.changePassword') }}</span>
                    </template>
                    <div class="col-12 margin-cards">
                        <dismissible-alert :trigger="alers.changePassword.success" :message="$t('settings.menu.tab.changePassword.successMessage')" variant="success"/>
                        <dismissible-alert :trigger="alers.changePassword.error" :message="alers.changePassword.errorMsg" variant="danger" :seconds="8"/>
                        <b-row class="margin-rows">
                            <b-col>
                                <span class="fz-15 openSans-400 settings-input-label">{{ $t('settings.menu.tab.myProfile.oldPassword') }}</span>
                                <b-form-input
                                    v-model="passwordData.oldPassword"
                                    type="password"
                                    class="settings-input openSans-100 fz-18"
                                    :placeholder="$t('settings.menu.tab.changePassword.oldPasswordPlaceholder')"></b-form-input>
                            </b-col>
                            <b-col>
                                <b-button class="openSans-400 fz-14 ta-center button-secondary my-auto button-no-border forgot-password-button" size="sm">
                                    <span class="mx-1">{{ $t('settings.menu.tab.myProfile.forgotPassword') }}</span>
                                </b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <span class="fz-15 openSans-400 settings-input-label">{{ $t('settings.menu.tab.myProfile.newPassword') }}</span>
                                <b-form-input
                                    v-model="passwordData.newPassword"
                                    @focus="passwordRules = true"
                                    @blur="passwordRules = false"
                                    type="password"
                                    class="settings-input openSans-100 fz-18"
                                    :placeholder="$t('settings.menu.tab.changePassword.newPasswordPlaceholder')"></b-form-input>
                            </b-col>
                            <b-col>
                                <span class="fz-15 openSans-400 settings-input-label">{{ $t('settings.menu.tab.myProfile.confirmPassword') }}</span>
                                <b-form-input v-model="passwordData.confirmPassword" type="password" class="settings-input openSans-100 fz-18" :placeholder="$t('settings.menu.tab.changePassword.confirmPasswordPlaceholder')"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="margin-rows">
                            <ol class="text-left mt-3" v-show="passwordRules">
                                <li>{{ $t('settings.menu.tab.changePassword.passwordRules.size') }}</li>
                                <li>{{ $t('settings.menu.tab.changePassword.passwordRules.symbols') }}</li>
                                <li>{{ $t('settings.menu.tab.changePassword.passwordRules.caseCharacters') }}</li>
                                <li>{{ $t('settings.menu.tab.changePassword.passwordRules.number') }}</li>
                                <li>{{ $t('settings.menu.tab.changePassword.passwordRules.spaces') }}</li>
                            </ol>
                        </b-row>
                        <b-col cols="12" class="pl-0">
                            <div>
                                <h3 class="fz-15 openSans-400 settings-input-label">{{ $t('settings.menu.tab.myProfile.multifactorSecurity') }}</h3>
                            </div> 
                            <div>
                                <span class="fz-11 openSans-400 settings-input-legend">{{ $t('settings.menu.tab.myProfile.multifactorSecurityDisclaimer') }}<br>{{ $t('settings.menu.tab.myProfile.multifactorSecurityDisclaimer2') }}</span>
                            </div>
                            <b-col cols="12" class="multi-factor">
                                <div class="d-inline-flex">
                                    <b-form-checkbox v-model="enableFactor"/>
                                    <span class="cursor-pointer openSans-400 fz-13 black-label">{{ $t('settings.menu.tab.myProfile.enableMultifactor') }}</span>
                                </div>
                            </b-col>
                            <b-button class="openSans-600 fz-12 ta-center button-primary mr-100 " size="sm" @click="changePassword">
                                <span class="mx-1">{{ $t('settings.menu.buttons.updateSettings') }}</span>
                            </b-button>
                        </b-col>
                    </div>
                </b-tab>
            </b-tabs>
        </b-row>
    </div>
</div>
</template>
<script>

import SettingsService from '@/modules/settings/services/SettingsService.js'
import UploadFileModal from "@/modules/common/components/UploadFile.vue"
import SettingsTabs from "@/modules/settings/components/SettingsTabs";

import {SET_CURRENT_INSTANCE} from '@/modules/security/store/actions/PermissionsActions'
import DismissibleAlert from '@/modules/common/components/DismissibleAlert.vue'

import {mapState} from 'vuex'
import store from '@/store'

export default {
    name: 'TabMyProfile',
    components: {
        DismissibleAlert, UploadFileModal,SettingsTabs
    },
    data() {
        return {
            alers: {
                saveProfileSettings: false,
                saveNotificationSettings: false,
                changePassword: {
                    success: false,
                    error: false,
                    errorMsg: ''
                }
            },
            userSettings: {
                displayName: '',
                fullName: '',
                email: '',
                phoneNumber: '',
                avatarUrl: '',
                notification: null,
                notificationFrequency: null,
                apiKey: ''
            },
            options: {
                notification: ['SMS', 'Web', 'Email'],
                notificationFrequency: ['Anytime', 'Daily', 'Weekly'],
            },
            passwordData: {
                oldPassword: null,
                newPassword: null,
                confirmPassword: null
            },
            passwordRules: false,
    bots: [],
    cardDisplay: 'simplified',
    enableFactor: "",
    mainProps: { blank: true, blankColor: '#777', class: 'm1' }
}
    },
    methods: {
        initUserSettings() {
            SettingsService.getUserSettings().then(reponse => {
                let currenUser = reponse.data
                this.userSettings.displayName = currenUser['display-name']
                this.userSettings.fullName = currenUser['full-name']
                this.userSettings.email = currenUser['email']
                this.userSettings.phoneNumber = currenUser['phone-number']
                this.userSettings.avatarUrl = currenUser['avatar-url']
                this.userSettings.notification = currenUser['notification'] || 'SMS'
                this.userSettings.notificationFrequency = currenUser['notification-frequency'] || 'Daily'
            })
        },
        getApiKey() {
            SettingsService.getApiKey().then(response => {
                this.userSettings.apiKey = response.data.api_key
            })
        },
        hasPermission(permission) {
            return this.botPermissions.indexOf(permission) > -1
        },
        updateUserData() {
            let userSettings = {
                "display_name": this.userSettings.displayName,
                "full_name": this.userSettings.fullName,
                "email": this.userSettings.email,
                "phone_number": this.userSettings.phoneNumber,
            }
            SettingsService.saveUserProfile(userSettings).then(() => {
                this.alers.saveProfileSettings = !this.alers.saveProfileSettings
            })
        },
        updateNotificationSettings() {
            let userSettings = {
                "notification": this.userSettings.notification,
                "notification_frequency": this.userSettings.notificationFrequency,
            }
            SettingsService.saveNotificationSettings(userSettings).then(() => {
                this.alers.saveNotificationSettings = !this.alers.saveNotificationSettings
            })
        },
        uploadProfilePicture(imageData) {
            return SettingsService.uploadProfilePicture(imageData)
        },
        changePassword() {
            if (this.validateChangePassword()) {
                SettingsService.changePassword(this.passwordData.oldPassword, this.passwordData.newPassword).then(response => {
                    if (response.data.status != 'error') {
                        this.alers.changePassword.success = !this.alers.changePassword.success
                        this.passwordData.oldPassword = null
                        this.passwordData.newPassword = null
                        this.passwordData.confirmPassword = null
                    } else {
                        this.alers.changePassword.errorMsg = this.$i18n.t('settings.menu.tab.changePassword.errorMessage')
                        this.alers.changePassword.error = !this.alers.changePassword.error
                    }
                })
            }
        },
        showErrorAlert(message) {
            this.alers.changePassword.errorMsg = message
            this.alers.changePassword.error = !this.alers.changePassword.error
        },
        validateChangePassword() {
            if (!this.passwordData.oldPassword) {
                this.showErrorAlert(this.$i18n.t('settings.menu.tab.changePassword.required.oldPassword'))
                return false
            }
            if (!this.passwordData.newPassword) {
                this.showErrorAlert(this.$i18n.t('settings.menu.tab.changePassword.required.newPassword'))
                return false
            }
            if (!this.passwordData.confirmPassword) {
                this.showErrorAlert(this.$i18n.t('settings.menu.tab.changePassword.required.confirmPassword'))
                return false
            }
            if (this.passwordData.newPassword != this.passwordData.confirmPassword) {
                this.showErrorAlert(this.$i18n.t('settings.menu.tab.changePassword.passwordsDifferent'))
                return false
            } else {
                var validation = this.passwordPatternValidation(this.passwordData.newPassword)
                if (!validation.isValid) {
                    this.showErrorAlert(validation.errorMsg)
                    return false;
                }
            }
            return true
        },
        passwordPatternValidation(password) {
            var rules = [{
                exp: /^.{8,20}$/,
                msg: this.$i18n.t('settings.menu.tab.changePassword.rulesValidations.size')
            }, {
                exp: /[%&$+!@#*]/,
                msg: this.$i18n.t('settings.menu.tab.changePassword.rulesValidations.symbols')
            }, {
                exp: /[A-Z]/,
                msg: this.$i18n.t('settings.menu.tab.changePassword.rulesValidations.uppercase')
            }, {
                exp: /[a-z]/,
                msg: this.$i18n.t('settings.menu.tab.changePassword.rulesValidations.lowercase')
            }, {
                exp: /[0-9]/,
                msg: this.$i18n.t('settings.menu.tab.changePassword.rulesValidations.number')
            }, {
                exp: /^\S*$/,
                msg: this.$i18n.t('settings.menu.tab.changePassword.rulesValidations.spaces')
            }];

            var error = this.$i18n.t('settings.menu.tab.changePassword.rulesValidations.intro')
            var valid = true;
            rules.forEach(rule => {
                if (!rule.exp.test(password)) {
                    valid = false;
                    error += rule.msg + '\n';
                }

            })
            return { isValid: valid, errorMsg: error }
        },
        uploadProfilePictureCallback(reponse) {
            this.userSettings.avatarUrl = reponse.data.url
        },
        create() {
            this.bots = store.getters.getCurrentUser.bots
        },
        selectInstance(instance) {
            store.dispatch(SET_CURRENT_INSTANCE, { instanceName: instance.bot_name })
        },
        changeDisplay: function (display) {
          display == 'detailed' ? this.cardDisplay = 'detailed' : this.cardDisplay = 'simplified'
        },
        deleteProfile() {
          let filename = this.userSettings.avatarUrl
          filename = filename.split('/').reverse()[0]

          SettingsService.deleteProfileImage(filename).then(() => {
            this.userSettings.avatarUrl = ''
          })
        }
    },
    watch: {
        currentBot() {
            this.initUserSettings()
        }
    },
    computed: mapState({
        instances: state => state.User.user.bots,
        currentInstance: state => state.Permissions.currentInstanceName,
        currentBot: () => store.getters.getInstance,
        botPermissions: () => store.getters.getInstancePermissions
    }),
    created() {
        if (this.$store.getters.hasSelectedInstance) {
            this.initUserSettings()
        }
        this.getApiKey()
    }
}
</script>
<style scoped>

.black-label {
    color: #151515;
    margin: auto 0px;
}

.multi-factor {
    display: block;
    float: left;
    padding-left: 0px;
    margin: 25px 0px 45px 0px;
    text-align: left;
}

.forgot-password-button {
    float: left;
    margin-top: 30px !important;
    margin-left: 0px;
    padding-left: -15px;
}

.profile-image-display {
    min-width: 108px;
    min-height: 108px;
    border: 1px solid #ebebeb;
    background-color: #acacac;
    border-radius: 10px;
    height: 108px;
    width: 108px;
} 

.mr-100 {
    margin-right: 100%;
}

.mr-buttons-profile-pick{
    margin: auto 0px auto 20px;
}

.margin-rows {
    margin-bottom: 29px;
}

.fixed-buttons{
    float: right;
    position: absolute;
    z-index: 3;
    top: 0px;
    right: 70px;
}

.first{
    right:140px;
}

a.btn-secondary.btn-sm{
  display:flex;
  max-width:140px;
}

.button-secondary svg{
  margin-right: 0.25rem !important;
}

span.mx-1{
  margin: auto !important;
}

.percent-card {
  min-height: 120px;
  height:auto;
  margin-bottom:30px;
  width:96%;
}

.percent-card {
  min-height: 120px;
  height:auto;
  margin-bottom:30px;
  width:96%;
}

.simplified-card{
    min-height:80px;
}

.utility-card-icon-border-box{
  border:1px solid #ebebeb;
  width:40px;
  height:40px;
  display:table-cell;
  vertical-align:middle
}

div.utility-card-icon-border-box img{
  margin: 0 auto;
}

.margin-label-view {
  margin: auto 0px;
}
</style>