<template>
  <div class="container-base section-container-padding no-padding-left bg-white row ml-0 pl-0">
    <settings-tabs tab-name="security" class="col-2 pl-0 pr-0"/>
    <div class="myproj-container settings-my-instances-tabs col-10 mt-3">

      <b-alert
        class="text-left"
        :show="dismissCountDown"
        dismissible
        variant="success"
        @dismissed="dismissCountDown=0"
        @dismiss-count-down="countDownChanged">
        {{ $t('settings.labels.successMessage') }}
      </b-alert>

      <b-row class="d-flex">
        <div class="li-utility-set-container">

          <br>
          <b-row>
            <b-col>
              <div style="text-align:left; padding-bottom:11px; border-bottom: 1px solid #d3d3d3; width: 100%;">
                <span class="ontology-sections-title">Security</span>
              </div>
            </b-col>
          </b-row>
          <div class="text-left">
            <br>
            <span class="section-title">Secure Content</span>
            <br>
            <br>
            <b-form-group>
              <template>
                <b-form-checkbox v-model="secureContent"
                                 value="true"
                                 unchecked-value="false"
                                 stacked>
                  Enable enhanced content security features
                </b-form-checkbox>
              </template>
            </b-form-group>
          </div>
          <b-row class="li-header-initial-row text-left">
            <b-col cols="12">
              <div class="d-flex">
                <b-button class="button-primary" @click="saveConfig">Save</b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import SettingsTabs from "@/modules/settings/components/SettingsTabs";
import SettingsService from "@/modules/settings/services/SettingsService";

export default {
name: "SecurityTab",
  components: {SettingsTabs},
  data() {
    return {
      secureContent: false,
      dismissSecs: 7,
      dismissCountDown: 0
    }
  },
  methods: {
    getBot() {
      SettingsService.getBot(this.$router.currentRoute.params.bot).then(response => {
        this.secureContent = response.data.secure_content
      })
    },
    saveConfig() {
      let botSettings = {
        'secure_content': this.secureContent
      }
      let botName = this.$router.currentRoute.params.bot
      SettingsService.saveSecurity(botSettings, botName).then(() => {
        let rightClick = this.secureContent !== 'true'

        document.oncontextmenu = () => { return rightClick }
        document.oncopy = () => { return rightClick }
        document.onpaste = () => { return rightClick }
        this.showAlert()
      })
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    }
  },
  created() {
    this.getBot()
  }
}
</script>

<style scoped>

</style>