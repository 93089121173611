<template>
  <div class="container">
    <div class="card mt-4">
      <b-alert show variant="warning" class="mb-0">
        <h4 class="alert-heading">Unauthorized!</h4>
        You are not allowed to go to that route
      </b-alert>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Unauthorized"
  }
</script>

<style scoped>

</style>