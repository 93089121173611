<template>
  <div class="container-base section-container-padding">
    <icon-page-header
      :icon="'icon_utilitysets.svg'"
      :title="$t('utilitySets.title')"
      :sub-title="$t('utilitySets.subtitle')" />
      <button-group-utility :selected-set="selectedSet" @selected-set="receiveSelectedSet"/>
    <list-utility :utility_sets="utility_sets.all_utils" :title="selectedSet" :show_title_utility="!show_title_utility" :selectedSet="selectedSet"/>
    <br>
    <list-utility :utility_sets="utility_sets.all_utils" :title="$t('utilitySets.buttonGroup.all')" :show_title_utility="show_title_utility"/>
  </div>
</template>

<script>
import IconPageHeader from '@/modules/common/components/IconPageHeader'
import ListUtility from "@/modules/utilitySets/components/ListUtility";
import ButtonGroupUtility from "@/modules/utilitySets/components/ButtonGroupUtility";

export default {
  components: {ButtonGroupUtility, ListUtility, IconPageHeader},
  data() {
    return {
      selectedSet: 'utilitySets.listUtility.title',
      utility_sets: {
        all_utils: [
          { util: {
            title: 'utilitySets.listUtility.featuredCard.titles.knowledge',
            descriptionKey: 'utilitySets.listUtility.featuredCard.knowledge',
            iconUtility: 'icon_utility_insights.svg',
            enable: '',
            version:'V1.01',
            set: this.$t('utilitySets.buttonGroup.featured')
            }
          },
          { util: {
            title: 'utilitySets.listUtility.featuredCard.titles.claims',
            descriptionKey: 'utilitySets.listUtility.featuredCard.claims',
            iconUtility: 'icon_utility_claims.svg',
            enable: '',
            version:'V1.01',
            set: this.$t('utilitySets.buttonGroup.featured')
            }
          },
          { util: {
            title: 'utilitySets.listUtility.featuredCard.titles.docIntelligence',
            descriptionKey: 'utilitySets.listUtility.featuredCard.docIntelligence',
            iconUtility: 'icon_utility_changetracking.svg',
            enable: '',
            version:'V1.01',
            set: this.$t('utilitySets.buttonGroup.featured')
            }
          },
          { util: {
            title: 'utilitySets.listUtility.featuredCard.titles.searchCoverage',
            descriptionKey: 'utilitySets.listUtility.featuredCard.searchCoverage',
            iconUtility: 'icon_utility_searchcoverage.svg',
            enable: '',
            version:'V1.01',
            set: this.$t('utilitySets.buttonGroup.insurance')
            }
          },
          { util: {
            title: 'utilitySets.listUtility.featuredCard.titles.sentimentAnalysis',
            descriptionKey: 'utilitySets.listUtility.featuredCard.sentimentAnalysis',
            iconUtility: 'icon_utility_sentimentanalysis.svg',
            enable: '',
            version:'V1.01',
            set: this.$t('utilitySets.buttonGroup.lifesciences')
            }
          },
          { util: {
            title: 'utilitySets.listUtility.featuredCard.titles.q&a',
            descriptionKey: 'utilitySets.listUtility.featuredCard.q&a',
            iconUtility: 'icon_utility_autoqa.svg',
            enable: '',
            version:'V1.01',
            set: this.$t('utilitySets.buttonGroup.enterprise')
            }
          }
        ]
      },
      show_title_utility: true
    }
  },
  methods: {
    receiveSelectedSet(selectedSet) {
      this.selectedSet = selectedSet
    }
  }
}
</script>

