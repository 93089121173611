<template>
  <div class="container-base section-container-padding">
    <dismissible-alert :trigger="noInstance"
                       :message="'Your user is not connected to any instance, please contact Sorcero.'"
                       variant="danger" class="mt-4"
    />
    <icon-page-header
      :icon="'icon_mycontent.svg'"
      :title="title"
      :sub-title="subTitle" />

    <br>
    <div class="text-left">
      <h3 class="section-title-right-line openSans-100 fz-23">Links</h3>
    </div>
    <br>
    <b-row>
      <feature-card
          v-for="item in utility_sets.all_utils" :key="item.title"
          :utility-set="item.util"
          :button-label="'howTo.launch'"></feature-card>
    </b-row>
  </div>
</template>

<script>
import IconPageHeader from "@/modules/common/components/IconPageHeader";
import FeatureCard from "@/modules/howto/components/FeatureCard";
import {mapState} from 'vuex'
import store from '@/store'
import DismissibleAlert from "@/modules/common/components/DismissibleAlert";


export default {
  name: "HowTo",
  components: {FeatureCard, IconPageHeader, DismissibleAlert },
  data() {
    return {
      title: 'Welcome',
      subTitle: 'Here you will find some links to different views.',
      utility_sets: {
        all_utils: [
          { util: {
            title: 'howTo.links.eBook.title',
            descriptionKey: 'howTo.links.eBook.description',
            iconUtility: 'icon_nlp_capability.svg',
            enable: '',
            version:'V1.01',
            set: this.$t('utilitySets.buttonGroup.featured'),
            path: 'https://sorcero-images.s3.amazonaws.com/public-docs/Sorcero+use-case+ebook.pdf'
            }
          },
          { util: {
            title: 'howTo.links.quickStart.title',
            descriptionKey: 'howTo.links.quickStart.description',
            iconUtility: 'icon_nlp_capability.svg',
            enable: '',
            version:'V1.01',
            set: this.$t('utilitySets.buttonGroup.featured'),
            path: 'https://sorcero-images.s3.amazonaws.com/public-docs/Quick-Start-Guide-modified-theme.pdf'
            }
          },
          { util: {
            title: 'howTo.links.developers.title',
            descriptionKey: 'howTo.links.developers.description',
            iconUtility: 'icon_nlp_capability.svg',
            enable: '',
            version:'V1.01',
            set: this.$t('utilitySets.buttonGroup.featured'),
            path: 'https://sorcero-sli-backend-dev.herokuapp.com/docs#/'
            }
          }
          ]}
    }
  },
  computed: mapState({
    noInstance: () => store.getters.noInstance,
  })
}
</script>

<style scoped>

</style>