import Repository from '@/modules/common/utils/Repository.js'
import store from '@/store'

export default {
  getUsers() {
    return Repository.dbService.get('/user/search', {
      params: {
        'bot_name': store.getters.getInstance
      }
    })
  },
  getUserSettings() {
    return Repository.dbService.get('/user/get', {
      params: {
        'user_id': store.getters.getCurrentUser.userid
      }
    })
  },
  saveUserProfile(data) {
    return Repository.dbService.post('user/save_profile', data, {
      params: {
        'user_id': store.getters.getCurrentUser.userid
      }
    })
  },
  saveNotificationSettings(data) {
    return Repository.dbService.post('user/save_notification_settings', data, {
      params: {
        'user_id': store.getters.getCurrentUser.userid
      }
    })
  },
  uploadProfilePicture(formData) {
    return Repository.dbService.post('user/upload_profile_picture', formData, {
      headers: {
        "Content-Type": 'multipart/form-data'
      },
      params: {
        "user_id": store.getters.getCurrentUser.userid
      }
    })
  },
  changePassword(oldPassword, newPassword) {
    var data = {
      'old_password': oldPassword,
      'new_password': newPassword
    }
    return Repository.dbService.post('user/change_password', data, {
      params: {
        'user_id': store.getters.getCurrentUser.userid
      }
    })
  },
  deleteProfileImage(filename) {
    return Repository.dbService.delete('user/delete_profile_picture', {
      params: {
        'filename': filename,
        'user_id': store.getters.getCurrentUser.userid
      }
    })
  },
  getRolesAndPermissions() {
    return Repository.dbService.get('settings/get_roles_and_permission', {
      params: {
        'bot_name': store.getters.getInstance
      }
    })
  },
  saveRolesAndPermissions(rolesPermission) {
    return Repository.dbService.post('settings/save_roles_and_permission', rolesPermission, {
      params: {
        bot_name: store.getters.getInstance,
      }
    })
  },
  getBot(botName) {
    return Repository.dbService.get('/bots/get', {
      params: {
        'bot_name': botName
      }
    })
  },
  uploadBotPicture(formData, botName, editing=false) {
    return Repository.dbService.post('bots/upload_bot_picture', formData, {
      headers: {
        "Content-Type": 'multipart/form-data'
      },
      params: {
        "bot_name": botName || store.getters.getInstance,
        "editing": editing
      }
    })
  },
  saveBotProfile(botSettings, botName) {
    return Repository.dbService.post('/bots/save', botSettings, {
      params: {
        'bot_name': botName
      }
    })
  },
  saveUtilities(botSettings, botName) {
    return Repository.dbService.post('/bots/save_utilities', botSettings, {
      params: {
        'bot_name': botName
      }
    })
  },
  saveSecurity(botSettings, botName) {
    return Repository.dbService.post('/bots/save_security', botSettings, {
      params: {
        'bot_name': botName
      }
    })
  },
  deleteInstance(botName) {
    return Repository.dbService.delete('/bots/delete', {
      params: {
        "bot_name": botName
      }
    })
  },
  addInstance(botSettings) {
    return Repository.dbService.post('/bots/add', botSettings, {
      params: {
        'bot_name': store.getters.getInstance
      }
    })
  },
  editUser(user) {
    return Repository.dbService.post('/settings/add_user', user, {
      params: {
        'bot_name': store.getters.getInstance
      }
    })
  },
  deleteUser(userId) {
    return Repository.dbService.delete('/settings/delete_user',{
      params: {
        'user_id': userId,
        'bot_name': store.getters.getInstance
      }
    })
  },
  configSearch(botSettings, botName) {
    return Repository.dbService.post('/bots/config_search', botSettings, {
      params: {
        'bot_name': botName
      }
    })
  },
  getAskConfig(botName) {
    return Repository.dbService.get('/bots/ask_config', {
      params: {
        'bot_name': botName
      }
    })
  },
  getApiKey() {
    return Repository.dbService.get('/user/get_api_key', {
      params: {
        'user_id': store.getters.getCurrentUser.userid
      }
    })
  }
}