<template>
  <div class="container-base section-container-padding no-padding-left bg-white row pl-0 ml-0" >
    <settings-tabs tab-name="instance-settings" class="col-2 pl-0 pr-0"/>
    <div class="col-10 padding-l-25" >
      <b-row class="mt-3">
         <dismissible-alert :trigger="alers.saveInstanceSettings" :message="$t('settings.labels.settingsUpdateSuccess')" variant="success" class="col-12"/>
        <div class="col-4">
            <label class="text-left-display openSans-400 fz-15">Instance Name
              <b-img id="topright-1" :src="`/assets/images/${iconProjectDetail}`"></b-img>
            </label>
            <b-tooltip target="topright-1" placement="topright">
              Tooltip on the topright
            </b-tooltip>

            <b-form-input class="li-utility-set-detail-description openSans-100 fz-18"
                          placeholder="New Instance Name"
                          v-model="bot.name"
                          disabled
            ></b-form-input>
        </div>

        <div class="col-4">
          <label class="text-left-display openSans-400 fz-15">Database Name
            <b-img id="topright-2" :src="`/assets/images/${iconProjectDetail}`"></b-img>
          </label>
          <b-tooltip target="topright-2" placement="topright">
            Tooltip on the topright
          </b-tooltip>
          <b-form-input class="li-utility-set-detail-description openSans-100 fz-18"
                        v-model="bot.database_name"
                        disabled
          ></b-form-input>
        </div>
      </b-row>

      <br>
      <b-row>
        <div class="col-8">
          <label class="text-left-display openSans-400 fz-15">Instance Description
            <b-img id="topright-3" :src="`/assets/images/${iconProjectDetail}`"></b-img>
          </label>
          <b-tooltip target="topright-3" placement="topright">
            Tooltip on the topright
          </b-tooltip>
            <b-form-input class="li-utility-set-detail-description openSans-100 fz-18"
                          placeholder=""
                          v-model="bot.display_name"
                          required
            ></b-form-input>
        </div>
      </b-row>

      <br>
      <b-row>
        <div class="col-4">
          <label class="text-left-display openSans-400 fz-15">Instance Image</label>
          <p class="openSans-400 fz-12 text-left info-color-letter">Image size requirement: 60px by 60px</p>

          <b-row class="padding-row">
            <b-img v-if="bot.avatar_url" class="instance-image-display" :src="bot.avatar_url"></b-img>
            <b-img v-else class="instance-image-display" v-bind="mainProps"></b-img>
            <b-button v-b-modal.upload-bot-picture class="button-secondary mt-2 margin-btn openSans-300 fz-12">Upload New</b-button>
            <p class="openSans-400 fz-12 mt-3 margin-info info-color-letter">
              {{ instance.logo ? instance.logo : 'Companylogo.png' }}
            </p>
            <upload-file-modal
                    id="upload-bot-picture"
                    :title="'Upload bot picture'"
                    icon-name="icon_cip_api.svg"
                    accept="image/*"
                    :api-request="uploadBotPicture"
                    :callback="uploadBotPictureCallback"/>
          </b-row>

          <br>
          <b-button class="button-primary align-btn openSans-300 fz-12" :disabled="!this.bot.display_name || !this.bot.name" @click="updateBotData">Save</b-button>
        </div>
      </b-row>

      <br>
      <br>
      <br>
      <br>
      <div class="col-11">
        <h3 class="settings-div-line"/>
      </div>

      <b-row class="row-top">
        <div class="col-4">
          <span class="text-left-display openSans-400 fz-15 cursor-pointer" v-b-modal.modal-prevent-closing :disabled="currentUser !== bot.sponsor">
            Delete Instance
          </span>
          <p class="openSans-400 fz-12 text-left-display info-color-letter">This is delete all projects Lorem Ipsum etc.</p>
        </div>

        <b-modal
          id="modal-prevent-closing"
          ref="modal"
          title="Take a moment... are you sure absolutely sure?"
          @show="resetModal"
          @hidden="resetModal"
          @ok="handleOk"
          @shown="focusMyElement"
        >
          <h3 class="warning-msg-modal">Warning!</h3>
          <span> This action cannot be undone. This will permanently delete the <i>{{ bot.name }}</i> instance. All of the content will be lost.</span>
          <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group
              label-for="name-input"
              invalid-feedback="Name is required"
              :state="nameState"
            >
              <template #label>
                <br>
                <span>Please type <b>{{ bot.name }}</b> to confirm</span>
              </template>
              <b-form-input id="name-input"
                            v-model="name"
                            ref="focusThis"
                            v-on:keyup.enter="handleSubmit"
                            required
              ></b-form-input>
              <span v-if="verifyName" class="warning-msg">Name does not match</span>
            </b-form-group>
          </form>
        </b-modal>
      </b-row>

    </div>
  </div>
</template>
<script>
import store from "@/store";
import SettingsService from "@/modules/settings/services/SettingsService";
import UploadFileModal from "@/modules/common/components/UploadFile";
import {mapState} from 'vuex'
import SettingsTabs from "@/modules/settings/components/SettingsTabs";
import {GET_USER} from "@/modules/security/store/actions/UserActions";
import DismissibleAlert from '@/modules/common/components/DismissibleAlert.vue'


export default {
  name: 'instance-settings',
  components: {UploadFileModal,SettingsTabs,DismissibleAlert},
  data() {
    return {
      alers: {
        saveInstanceSettings: false
      },
      mainProps: { blank: true, blankColor: '#777', width: 60, height: 60, class: 'm1' },
      instance: {
        name: 'Irish Life Brokerage',
        databaseName: 'Irish Life',
        logo: '',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. '
      },
      iconProjectDetail : 'icons-project-details-sm-green.svg',
      selected: this.$router.currentRoute.params.bot,
      bots: store.getters.getCurrentUser.bots,
      bot: {},
      name: null,
      nameState: null,
      checked: null,
      verifyName: false,
      currentUser: store.getters.getCurrentUser.userid,
      isDeletedBot: true
    }
  },
  methods: {
    getBot() {
      SettingsService.getBot(this.currentBot).then(response => {
        this.bot = response.data
      })
    },
    uploadBotPicture(imageData) {
      return SettingsService.uploadBotPicture(imageData, this.selected, true)
    },
    uploadBotPictureCallback(response) {
      this.bot['avatar_url'] = response.data.url
    },
    getUserInstances(){
      store.dispatch(GET_USER)
    },
    updateBotData() {
      let botSettings = {
        'display_name': this.bot.display_name,
        'name': this.bot.name,
        'avatar_url': this.bot.avatar_url
      }
      SettingsService.saveBotProfile(botSettings, this.selected).then(() => {
        this.getUserInstances()
        this.getBot()
        this.alers.saveInstanceSettings = !this.alers.saveInstanceSettings
      })
    },
    checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.nameState = valid
        return valid
      },
      resetModal() {
        this.name = ''
        this.nameState = null
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {
        if (!this.checkFormValidity()) {
          this.verifyName = false
          return
        }
        if (!this.checkName()) {
          this.verifyName = true
          return
        }
        SettingsService.deleteInstance(this.bot.name).then(response => {
          this.isDeletedBot = response.data
          if (this.isDeletedBot) {
            this.getUserInstances()
            this.goToMyInstances()
          }
        })
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
      },
      focusMyElement() {
        this.$refs.focusThis.focus()
      },
      checkName() {
        this.checked = this.name === this.bot.name
        return this.checked
      },
      goToMyInstances() {
        this.$router.push(`/${this.$router.currentRoute.params.bot}/settings/my-instances`)
      },
  },
  watch: {
    currentBot() {
      this.getBot()
    }
  },
  computed: mapState({
    currentBot: () => store.getters.getInstance
  }),
  created() {
    if (this.$store.getters.hasSelectedInstance) {
      this.getBot()
    }
  }}
</script>
<style scoped>

.button-secondary svg {
  margin-right: 0.25rem !important;
}

div.utility-card-icon-border-box img {
  margin: 0 auto;
}

.padding-row {
  padding-left: 15px;
}

.margin-btn {
  margin-left: 30px;
}

.margin-info {
  margin-left: 10px;
}

.padding-l-25 {
  padding-left: 25px;
}

.align-btn {
  display: block;
}

.row-top{
  margin-top: 22px;
}

.info-color-letter {
  color: #707070
}

h3.settings-div-line {
    color: var(--dark-grey);
    display: flex;
    align-items: center;
    justify-content: center;
}

h3.settings-div-line span {
    background: #fff;
    margin: 0 0;
}

h3.settings-div-line:after {
    background: #dadada;
    margin-left:20px;
    height: 2px;
    flex: 1;
    content: '';
}

.instance-image-display {
    min-width: 108px;
    min-height: 108px;
    border: 1px solid #ebebeb;
    background-color: #acacac;
    border-radius: 10px;
    height: 108px;
    width: 108px;
}

.warning-msg-modal {
    font-family: OpenSans;
    font-size: 25px;
    color: #e90000;
    margin: 10px 0px
}
</style>