<template>
  <div class="col-12">
    <dismissible-alert :trigger="alerts.nameInstance.success" :message="$t('settings.menu.tab.validationsInstance.successInstance')" variant="success"/>
    <dismissible-alert :trigger="alerts.nameInstance.error" :message="alerts.nameInstance.errorMsg" variant="danger" :seconds="8"/>
    <b-row>
      <div class="col-4">
        <label class="text-left-display openSans-400 fz-15">Instance Name
          <b-img id="topright-1" :src="`/assets/images/${iconProjectDetail}`"></b-img>
        </label>
        <b-tooltip target="topright-1" placement="topright">
          Tooltip on the topright
        </b-tooltip>

        <b-form-input class="li-utility-set-detail-description openSans-100 fz-17"
                      placeholder="Enter Instance Name"
                      v-model="instance.name"
                      required
        ></b-form-input>
      </div>

      <div class="col-4">
        <label class="text-left-display openSans-400 fz-15">Database Name
          <b-img id="topright-2" :src="`/assets/images/${iconProjectDetail}`"></b-img>
        </label>
        <b-tooltip target="topright-2" placement="topright">
          Tooltip on the topright
        </b-tooltip>
        <b-form-input class="li-utility-set-detail-description openSans-100 fz-18"
                      v-model="instance.databaseName"
                      required
                      disabled
                      max-length="20"
        ></b-form-input>
      </div>
    </b-row>

    <br>
    <b-row>
      <div class="col-8">
        <label class="text-left-display openSans-400 fz-15">Instance Description
          <b-img id="topright-3" :src="`/assets/images/${iconProjectDetail}`"></b-img>
        </label>
        <b-tooltip target="topright-3" placement="topright">
          Tooltip on the topright
        </b-tooltip>
          <b-form-input class="li-utility-set-detail-description openSans-100 fz-18"
                        placeholder=""
                        v-model="instance.display_name"
                        required
          ></b-form-input>
      </div>
    </b-row>

    <br>
    <b-row>
      <div class="col-4">
        <label class="text-left-display openSans-400 fz-15">Instance Image</label>

        <b-row class="padding-row">
          <b-img v-if="instance.avatarUrl" class="instance-image-display" :src="instance.avatarUrl"></b-img>
          <b-img v-else class="instance-image-display" v-bind="mainProps"></b-img>
          <b-button v-b-modal.new-bot-picture class="button-secondary mt-2 margin-btn openSans-300 fz-12">Upload New</b-button>
          <p class="openSans-400 fz-12 mt-3 margin-info info-color-letter">
            {{ instance.logo ? instance.logo : 'Companylogo.png' }}
          </p>
          <upload-file-modal
                  id="new-bot-picture"
                  :title="'Upload bot picture'"
                  icon-name="icon_cip_api.svg"
                  accept="image/*"
                  :api-request="uploadBotPicture"
                  :callback="uploadBotPictureCallback"/>
        </b-row>

        <br>
        <b-button class="button-primary align-btn openSans-300 fz-12" @click="saveNewInstance">Add New Instance</b-button>
      </div>
    </b-row>
  </div>
</template>
<script>
import DismissibleAlert from "@/modules/common/components/DismissibleAlert";
import SettingsService from "@/modules/settings/services/SettingsService";
import UploadFileModal from "@/modules/common/components/UploadFile";
import store from "@/store";

export default {
  name: 'add-new-instance',
  components: {
    DismissibleAlert,
    UploadFileModal
  },
  props: {
    callback: Function
  },
  data() {
    return{
      mainProps: { blank: true, blankColor: '#777', width: 60, height: 60, class: 'm1' },
      instance: {
        name: '',
        lowerCase: '',
        databaseName: '',
        logo: '',
        display_name: '',
        avatarUrl: ''
      },
      iconProjectDetail : 'icons-project-details-sm-green.svg',
      alerts: {
        nameInstance: {
            success: false,
            error: false,
            errorMsg: ''
        }
      }
    }
  },
  methods: {
    saveNewInstance() {
      var validation = this.patternValidation(this.instance.name)
      if (!validation.isValid) {
          this.showErrorAlert(validation.errorMsg)
          return false;
      }
      this.instance.lowerCase = this.instance.name.toLocaleLowerCase().trim()
      let botSettings = {
        'display_name': this.instance.display_name,
        'name': this.instance.lowerCase,
        'avatar_url': this.instance.avatarUrl,
        'sponsor': store.getters.getCurrentUser.userid
      }
      SettingsService.addInstance(botSettings).then(response => {
        if(response.data.status) {
          this.alerts.nameInstance.success = !this.alerts.nameInstance.success
          this.callback()
          this.cleanFormInstance()
        } else {
          this.showErrorAlert(response.data.detail)
        }
      })
      .catch(error => {
        console.log(error)
      })
    },
    patternValidation(nameInstance) {
      var rules = [{
          exp: /^.{3,20}$/,
          msg: this.$i18n.t('settings.menu.tab.validationsInstance.size')
      }, {
          exp: /^\S*$/,
          msg: this.$i18n.t('settings.menu.tab.validationsInstance.spaces')
      }, {
          exp: /^[a-zA-Z0-9-]+$/,
          msg: this.$i18n.t('settings.menu.tab.validationsInstance.alphaNumericDashUnderscore')
      }];

      var error = this.$i18n.t('settings.menu.tab.validationsInstance.intro')
      var valid = true;
      rules.forEach(rule => {
          if (!rule.exp.test(nameInstance)) {
              valid = false;
              error += rule.msg + '\n';
          }

      })
      return { isValid: valid, errorMsg: error }
    },
    showErrorAlert(message) {
      this.alerts.nameInstance.errorMsg = message
      this.alerts.nameInstance.error = !this.alerts.nameInstance.error
    },
    uploadBotPicture(imageData) {
      return SettingsService.uploadBotPicture(imageData)
    },
    uploadBotPictureCallback(response) {
      this.instance['avatarUrl'] = response.data.url
    },
    cleanFormInstance() {
      this.instance.lowerCase = ''
      this.instance.name = ''
      this.instance.display_name = ''
      this.instance.avatarUrl = ''
    }
  }
}
</script>
<style scoped>

.button-secondary svg {
  margin-right: 0.25rem !important;
}

div.utility-card-icon-border-box img {
  margin: 0 auto;
}

.padding-row {
  padding-left: 15px;
}

.info-color-letter {
  color: #707070
}

.margin-info {
  margin-left: 10px;
}

.align-btn {
  display: block;
}

.instance-image-display {
    min-width: 108px;
    min-height: 108px;
    border: 1px solid #ebebeb;
    background-color: #acacac;
    border-radius: 10px;
    height: 108px;
    width: 108px;
}
</style>