<template>
  <div class="recipe-menu">
      <b-tabs v-model="index" pills card vertical class="recipe-menu-items openSans-14">
        <b-tab v-if="hasPermission('settings')" :title="$t('settings.menu.tab.settingsLabel.title')" disabled></b-tab>
        <b-tab v-if="hasPermission('my-profile')" :title="$t('settings.menu.tab.myProfile.title')"
               @click="goTo('my-profile')" ></b-tab>
        <b-tab v-if="hasPermission('my-instances')" :title="$t('settings.menu.tab.myInstances.title')"
               @click="goTo('my-instances')" ></b-tab>
        <b-tab v-if="hasPermission('instance')" disabled>
          <template v-slot:title >
            <span class="disabledTab"> {{ `${displayName} ${$t('settings.menu.tab.settingsLabel.title')}` }} </span>
          </template>
        </b-tab>
        <b-tab v-if="hasPermission('instance-settings')" :title="$t('settings.menu.tab.myInstances.instanceSettings')"
               @click="goTo('instance')"></b-tab>
        <b-tab v-if="hasPermission('utility-sets')" title="Utility Sets"
               @click="goTo('utility-sets')"></b-tab>
        <b-tab v-if="hasPermission('roles-and-permission')" :title="$t('settings.menu.tab.rolesAndPermission.title')"
               @click="goTo('roles-and-permission')"></b-tab>
        <b-tab v-if="hasPermission('user-admin-settings')" :title="$t('settings.menu.tab.userAdminSettings.title')"
               @click="goTo('user-admin')"></b-tab>
        <b-tab v-if="hasPermission('search-settings')" title="Search Settings"
               @click="goTo('search-settings')"></b-tab>
        <b-tab v-if="hasPermission('security')" :title="$t('settings.menu.tab.security.title')"
               @click="goTo('security')"></b-tab>
      </b-tabs>
  </div>
</template>
<script>
import { PERM_MODIFY_SETTINGS, PERM_FREE, PERM_MODIFY_USER_SETTINGS} from '@/modules/security/services/SecurityService.js'
import store from '@/store'

export default {

  name: 'SettingsTabs',

  data () {
    return {
      index: null,
      indexInstance: null,
      permission: null,
      displayName: '',
      definedTabs: [{
        name: 'settings',
        permission: PERM_MODIFY_SETTINGS
      }, {
        name: 'my-profile',
        permission: PERM_MODIFY_USER_SETTINGS
      }, {
        name: 'my-instances',
        permission: PERM_FREE
      }, {
        name: 'instance',
        permission: PERM_MODIFY_SETTINGS
      }, {
        name: 'instance-settings',
        permission: PERM_MODIFY_SETTINGS
      }, {
        name: 'utility-sets',
        permission: PERM_MODIFY_SETTINGS
      }, {
        name: 'roles-and-permission',
        permission: PERM_MODIFY_SETTINGS
      }, {
        name: 'user-admin-settings',
        permission: PERM_MODIFY_SETTINGS
      }, {
        name: 'search-settings',
        permission: PERM_MODIFY_SETTINGS
      }, {
        name: 'security',
        permission: PERM_MODIFY_SETTINGS
      }],
      orderTabs: []
    }
  },
  props: {
    tabName: String,
  },
  methods: {
    goTo(route) {
      var currentBot = this.$router.currentRoute.params.bot? this.$router.currentRoute.params.bot : store.getters.getInstance
      if(currentBot){
        this.$router.push(`/${currentBot}/settings/${route}`).catch(() => {})
      } 
    },
    hasPermission(permission) {
      return this.orderTabs.indexOf(permission) > -1
    },
    setTabsOrder() {
      this.orderTabs = []
      this.definedTabs.forEach(view => {
        if (view.permission == PERM_FREE || this.botPermissions.indexOf(view.permission) > -1) {
          this.orderTabs.push(view.name)
        }
      })
    }
  },
  watch: {
    botPermissions() {
      this.setTabsOrder()
    }
  },
  computed: {
    botPermissions() {
      return store.getters.getInstancePermissions
    }
  },
  created() {
    this.displayName = this.$router.currentRoute.params.bot
    this.setTabsOrder()
    this.index = this.orderTabs.indexOf(this.tabName) != -1 ? this.orderTabs.indexOf(this.tabName) : null
  }
}
</script>

<style lang="scss">


.disabledTab{
  font-family: "OpenSans" !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #686868 !important;
}

.settings-my-instances-tabs ul.nav.nav-tabs {
  margin: 0px 0px 30px 0px;
}

.recipe-menu { 
  border-right: 1px solid #dedede;
}

  div.tabs.recipe-menu-items {
    top: 0px;
    position: sticky;

    div.col-auto {
      width: 100%
    }
  }
</style>
