<template>
  <div class="container-base">
      <create-proyect/>
  </div>
</template>

<script>
// @ is an alias to /src
import CreateProyect from '@/modules/common/components/CreateProyect.vue'

export default {
  name: 'CreateProyect',
  components: {
    'create-proyect': CreateProyect
  }
}
</script>
