<template>
  <div class="container-base section-container-padding bg-white">
    <icon-page-header
      :icon="'icon_myapps.svg'"
      :title="$t('myApps.fullTitle')"
      :sub-title="$t('myApps.subtitle')" />
      <div class="d-flex">
        <feature-card
          v-for="app in myApps" :key="app.title"
          :detail-data="app.data"
          :button-label="$t('myApps.launch')"
          :card-type= "'myapps'"
          :show-button-icon="true"></feature-card>
        <!--<browse-apps-card :show-button-icon="false"/>-->
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import IconPageHeader from '@/modules/common/components/IconPageHeader'
import FeatureCard from "@/modules/common/components/FeatureCard"
// import BrowseAppsCard from "@/modules/common/components/BrowseAppsCard"


export default {
  name: 'MyApps',
  components: { IconPageHeader, FeatureCard},
  data() {
    return {
      myApps: []
    }
  },
  methods: {
    initCards() {
      this.myApps = [{
        data: {
          title: 'myApps.knowledge.name',
          descriptionKey: 'myApps.knowledge.description',
          version: 'V 1.01',
          iconUtility: "icon_utility_insights.svg",
          path: `/${this.currentInstance}/my-apps/knowledge/ask`
        },
      }]
    }
  },
  created() {
    if (this.$store.getters.hasSelectedInstance) {
      this.initCards()
    }
  },
  computed: {
    currentInstance: function() {return this.$store.getters.getInstance}
  },
  watch: {
    currentInstance : function() {
      this.initCards()
    }
  }
}
</script>
