<template>
  <div>
    <b-alert 
      class="text-left"
      :show="dismissCountDown"
      :variant="variant"
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
      dismissible>
      <p class="m-0" v-html="message"></p>
    </b-alert>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        dismissSecs: 5,
        dismissCountDown: 0
      }
    },
    props: {
      trigger: Boolean,
      message: String,
      seconds: Number,
      variant: String
    },
    methods: {
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      showAlert() {
        this.dismissCountDown = this.dismissSecs
      }

    },
    watch: {
      trigger: function() {
        this.showAlert()
      }
    },
    created() {
      if (this.seconds) {
        this.dismissSecs = this.seconds
      }
    }
  }
</script>