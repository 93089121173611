<template>
  <div class="section-container-padding">
  <icon-page-header
    :icon="'icon_create_proyect.svg'"
    :title="$t('proyect.create.title')"
    :sub-title="$t('proyect.create.subtitle')" />
    <b-row>
        <create-proyect-card 
            v-for="item in createProyectOptions" 
            :key="item.title" 
            :card_suggested="item.suggested" 
            :card_title="item.title" 
            :card_icon="item.icon"
            :card_route="item.route" 
            :card_description="item.description"/>
    </b-row>
  </div>
</template>

<script>
import CreateFirstProyectCard from '@/modules/common/components/CreateFirstProyectCard.vue'
import IconPageHeader from '@/modules/common/components/IconPageHeader.vue'

export default {
  name: 'CreateProyect',
  props: {},
  components: {
  'create-proyect-card': CreateFirstProyectCard,
  'icon-page-header': IconPageHeader
  },
  data () {
    return {
        createProyectOptions: [{
            title: 'proyect.create.usePreloadTitle',
            description: 'proyect.create.usePreloadDescription',
            icon: 'icon_onboarding_preloaded.svg',
            suggested: true,
            route: '/projects'
        },
        {
            title: 'proyect.create.uploadSampleTitle',
            description: 'proyect.create.uploadSampleDescription',
            icon: 'icon_onboarding_sample.svg',
            suggested: false,
            route: ''
        },
        {
            title: 'proyect.create.createNewTitle',
            description: 'proyect.create.createNewDescription',
            icon: 'icon_onboarding_newproyect.svg',
            suggested: false,
            route: '/ingestion-project/create'
        }]
    }
  }
}
</script>

<style scoped>
</style>
